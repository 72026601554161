/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'move-in': {
    width: 18,
    height: 21,
    viewBox: '0 0 18 21',
    data: '<path pid="0" d="M15.65.573a.602.602 0 01.22.821l-2.383 4.127 1.304-.349a.6.6 0 11.311 1.16l-2.705.724a.6.6 0 01-.734-.424l-.725-2.705a.6.6 0 111.159-.31l.35 1.303L14.857.785a.6.6 0 01.792-.212z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M1.234 13.848l.383-.924A.994.994 0 002 13h.875v1H2c-.271 0-.53-.054-.766-.152zM15.125 14v-1H16a.994.994 0 00.383-.075l.383.923A1.996 1.996 0 0116 14h-.875zM18 10.5h-1V10a.994.994 0 00-.076-.383l.924-.383c.098.236.152.495.152.766v.5zM2.875 8H2c-.271 0-.53.054-.766.152l.383.924A.994.994 0 012 9h.875V8zM0 11.5h1v.5c0 .137.027.266.075.383l-.923.383A1.994 1.994 0 010 12v-.5zm0-1h1V10c0-.137.027-.266.075-.383l-.923-.383A1.994 1.994 0 000 10v.5zM4.625 8v1h1.75V8h-1.75zm3.5 0v1h1.75V8h-1.75zm3.5 0v1h1.75V8h-1.75zm3.5 0v1H16c.137 0 .266.027.383.075l.383-.923A1.994 1.994 0 0016 8h-.875zM18 11.5h-1v.5a.994.994 0 01-.076.383l.924.383c.098-.236.152-.495.152-.766v-.5zM13.375 14v-1h-1.75v1h1.75zm-3.5 0v-1h-1.75v1h1.75zm-3.5 0v-1h-1.75v1h1.75zM16 16H2a1 1 0 00-1 1v2a1 1 0 001 1h14a1 1 0 001-1v-2a1 1 0 00-1-1zM2 15a2 2 0 00-2 2v2a2 2 0 002 2h14a2 2 0 002-2v-2a2 2 0 00-2-2H2z" _fill="#000"/>'
  }
})
